import Vue from 'vue'
import App from './App.vue'
import './assets/css/brand.css'
import 'tachyons'

Vue.config.productionTip = false


const DIAGRAPHICS_THEME = {
  style: {
    '--theme-background' : 'var(--brand---black)',
    '--theme-linework': 'var(--brand---white-0)'
  },
  wordmark: "brand/diagraphics-wordmark-compact.svg"
};

const LAMINA_THEME =  {
  style: {
  '--theme-background' : 'var(--brand---blue-5)',
  '--theme-linework': 'var(--brand---blue-1)'
  },
  wordmark: "lamina.svg",
};


const THEMES = [
  {
    pattern: /lamina/,
    ...LAMINA_THEME,
  },
  {
    pattern: /diagraphics/,
    ...DIAGRAPHICS_THEME,
  },
  {
    pattern: /localhost/,
    ...LAMINA_THEME,
  },
  {
    pattern: /127\.0\.0\.1/,
    ...DIAGRAPHICS_THEME,
  }
];

const hostname = window.location.hostname;

Vue.prototype.$hostname = hostname
Vue.prototype.$theme = THEMES.find(({ pattern }) => hostname.match(pattern));

new Vue({
  render: h => h(App),
}).$mount('#app')
