<template>
  <div class="flex justify-between">
      <img :src="`${publicPath}${$theme.wordmark}`" class="w4 w5-l">
      <ContactButton/>
  </div>
</template>

<script>
import ContactButton from './ContactButton.vue'

export default {
  name: 'Banner',
  components: {
    ContactButton,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
/* Slide Title */

.container {
  position: sticky;
}

.title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 58px;

  /* Brand/White 0 */
  color: #ffffff;
  margin: 0;
}
</style>