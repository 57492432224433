<template>
  <div class="wrapper">
    <div class="container" v-if="imagesLoaded">
      <div class="item" v-for="(img, idx) in items" :key="idx">
        <InlineSvg :src="img.src" v-on="isLastItem(idx) ? { click: addShape } : {}"/>
      </div>
    </div>
  </div>
</template>

<script>
const NUM_SHAPES = 34;

import sample from 'lodash/sample';
import InlineSvg from 'vue-inline-svg';

function lazyImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = () => reject('Error loading image.');
  });
}

export default {
  name: 'ShapeGrid',
  components: {
    InlineSvg,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      images: Array(NUM_SHAPES),
      items: [],
      imagesLoaded: false,
    };
  },
  async created() {
    const images = Array(NUM_SHAPES)
    for (let ix = 0; ix < NUM_SHAPES; ix += 1) {
        const idx = ix.toString().padStart(2, "0");
        const src = `${this.publicPath}shapes/Shape${idx}.svg`;
      images[ix] = lazyImage(src);
    }
    
    const shapeAdd = await lazyImage(`${this.publicPath}shapes/ShapeAdd.svg`);
  
    try {
      const loadedImages = await Promise.all([...images, shapeAdd]);
      this.items = loadedImages;
      this.images = [...loadedImages];
      this.images.pop();
    } catch (e) {
      console.error("Some images did not pre-load");
    } finally {
      this.imagesLoaded = true;
    }
  },
  mounted() {
  },
  methods: {
    addShape() {
      this.items.splice(-1, 0, sample(this.images));
      this.$children.at(-1).$el.scrollIntoView();
    },
    isLastItem(ix) {
      return ix == this.items.length - 1;
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;
}
</style>

<style>
svg path {
  stroke: var(--theme-linework);
}

svg:hover rect, svg:hover path {
  stroke: var(--brand---gold-5);
}
</style>