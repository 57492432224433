<template>
  <div class="container" ref="container">
    <!-- <button class="f6 link dim br2 ph3 pv2 mb2 dib white bg-black">Contact us</button> -->
    <button
      data-tf-popup="LJWN9miX"
      data-tf-iframe-props="title=Lamina Contact Form"
      style="
        all: unset;
        font-family: Helvetica, Arial, sans-serif;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #ffc53d;
        color: #000000;
        font-size: 20px;
        border-radius: 5px;
        padding: 0 33px;
        font-weight: bold;
        cursor: pointer;
        line-height: 50px;
        text-align: center;
        margin: 0;
        text-decoration: none;
      "
    >
      Contact Us
    </button>
  </div>
</template>

<script>
export default {
  mounted() {
    let typeformScript = document.createElement("script");
    typeformScript.setAttribute("src", "//embed.typeform.com/next/embed.js");
    this.$refs.container.appendChild(typeformScript);
  },
};
</script>

<style scoped>
button {
  border: 0;
  width: 250px;
  height: 32px;
  background-color: var(--brand---gold-5);
}
</style>