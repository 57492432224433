<template>
  <div id="app" :style="$theme.style">
    <header id="banner-wrapper" class="pa3 ph3 ph5-n ph6-l">
      <div id="banner">
        <Banner />
      </div>
    </header>
    <main id="content" class="ph3 ph5-n ph6-l">
      <ShapeGrid />
    </main>
  </div>
</template>

<script>
import ShapeGrid from "./components/ShapeGrid.vue";
import Banner from "./components/Banner.vue";

export default {
  name: "App",
  components: {
    ShapeGrid,
    Banner,
  },
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: var(--theme-background);
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

header, main, #banner, #content {
  width: 100%;
}

#banner-wrapper {
  position: sticky;
  top: 0;
  background-color: var(--theme-background);
}
</style>
